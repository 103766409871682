















































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AffichageCandidatsIncompatibles from '@/components/GestionIncompatibles/AffichageCandidatsIncompatibles.vue'

@Component({
    computed: {
        ...mapGetters('candidatIncompatible', ['listeCandidatsIncompatibles', 'loading', 'listeCandidatsIncompatiblesGroupee', 'loadingRechercheEtablissement', 'resultatRechercheParEtablissement', 'selectedExaminateur', 'allEtablissements', 'listeIdRechercheParEtablissement', 'resultatRechercheParEtablissementTrie'])
    },
    components: {
        'affichage-resultat':AffichageCandidatsIncompatibles
    }
})
export default class RechercheParEtablissement extends Vue {
    @Prop() examinateurSide?: boolean

    resultatRecherche   = []
    listeEtablissements = []
    /* Récupération des id des candidats pour envois au back */
    resultatRechercheCandidatId = []
    /* Utiliser pour vérifier si une recherche a deja eu lieux et afficher le bon message si le resultat ne comporte pas de resultat */
    firstSearch = false
    isLoading   = this.$store.getters['candidatIncompatible/loadingRechercheEtablissement']
    recherche   = {
        etablissement_id: 0,
        user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
        classe: '*'
    }

    beforeMount(): void {
        this.$store.dispatch('candidatIncompatible/GET_ALL_ETABLISSEMENTS')
        this.listeEtablissements = this.$store.getters['candidatIncompatible/allEtablissements']
    }

    @Watch('allEtablissements')
    watchGetEtablissementById(): void {
        this.listeEtablissements = this.$store.getters['candidatIncompatible/allEtablissements']
    }

    @Watch('resultatRechercheParEtablissement')
    getResultatRecherche(): void {
        this.resultatRecherche = this.$store.getters['candidatIncompatible/resultatRechercheParEtablissement']
    }

    @Watch('loadingRechercheEtablissement')
    watchLoading(): void {
        this.isLoading = this.$store.getters['candidatIncompatible/loadingRechercheEtablissement']
    }

    @Watch('selectedExaminateur')
    getExaminateur(): void {
        this.recherche.user_id = this.$store.getters['candidatIncompatible/selectedExaminateur']
    }

    @Watch('resultatRechercheParEtablissementTrie')
    getIdCandidatResultat(): void {
        this.resultatRechercheCandidatId = this.$store.getters['candidatIncompatible/resultatRechercheParEtablissementTrie']
    }

    /**
     * @description Recupere l'objet recherche, ajoute l'user_id dans l'objet puis dispatch
     * @returns {void}
     */
    rechercher(): void {
        this.recherche.user_id = this.$store.getters['candidatIncompatible/selectedExaminateur']
        this.isLoading = true
        this.resultatRecherche = []
        this.$store.dispatch('candidatIncompatible/RECHERCHE_PAR_ETABLISSEMENT', this.recherche)
        this.firstSearch = true
    }

    /**
     * @description Fonction app lors du clic sur annuler, RAS dans le store de resultatRechercheCandidat/ résultat de recherche et envois le nom de l'onglet à afficher
     * @returns {void}
     */
    annulerRecherche(): void {
        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.$emit('afficher')
        this.resultatRecherche = []
        /* Utilisé coté examinateur pour fermer la modale */
        this.$bvModal.hide('recherche_par_etablissement_modal')
        this.cleanSearchArea()
    }

    /**
     * @description Dispatch des candidats incompatibles / emit le nom de l'onglet à afficher
     * @returns {void}
     */
    addIncompatibles(): void {
        const params = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            candidats: this.resultatRechercheCandidatId
        }
        this.$store.dispatch('candidatIncompatible/addCandidats', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000,
                    appendToast: false
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
        /* Envois au parent, la div à afficher, dans ce cas, retour à la liste des incompatibles */
        this.$emit('afficher')
        this.cleanSearchArea()
        if (this.$props.examinateurSide === true) {
            this.$bvModal.hide('recherche_par_etablissement_modal')
        }
    }

    /**
     * @description Supprimer tous les champs de recherche
     * @returns {void}
     */
    cleanSearchArea(): void {
        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.recherche = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            etablissement_id: 0,
            classe: '*'
        }
        this.resultatRechercheCandidatId = []
    }

    /**
     * @description Recuperation des classes d'un établissement pour l'afficher dans le champ recherche
     * @returns {string}
     */
    classes(): string {
        return (this.listeEtablissements
            ?.find((element: { id: any }) => element.id === this.recherche.etablissement_id) as any)
            ?.classes || ''
    }
}
