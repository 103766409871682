



























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'

import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

@Component({
    components: {
        VueTelInput
    }
})
export default class PhoneInput extends Vue {
    @Prop() propPhoneNumber?: string;
    @Prop() propRequired?: boolean;
    @Prop() propInputReadOnly?: boolean;
    @Prop() propInputDisabled?: boolean;
    @Prop() propDropdownDisabled?: boolean;
    @Prop() propDefaultCountry?: string;
    @Prop() propPreferredCountries?: string;
    @Prop({default: true}) checkValidity?:boolean

    Ability = Ability

    inputPhoneNumber = ''

    infosPhoneInput = {
        phoneNumber: '',
        formattedPhoneNumber: '',
        isValidatedPhoneNumber: false
    }

    isInvalid = ''
    inputBaseClass = ''

    vueTelInputProps = {
        validCharactersOnly: true,
        inputOptions: {
            id: 'phone_input',
            placeholder: '',
            required: this.$props.propRequired !== null ? this.$props.propRequired : true,
            readonly: true,
            type: 'tel',
            styleClasses: 'form-control vuetelinput_class'
        },
        // onlyCountries: ['FR', 'GF', 'PF'],
        defaultCountry: this.$props.propDefaultCountry ? this.$props.propDefaultCountry : 'FR',
        preferredCountries: this.$props.propPreferredCountries ? this.$props.propPreferredCountries : ['FR', 'NC', 'GF', 'MQ', 'PF', 'GP', 'RE', 'MA', 'MR', 'CM', 'CI', 'LB', 'TN', 'GA', 'BF'],
        dropdownOptions: {
            showDialCodeInList: false,
            showDialCodeInSelection: true,
            showFlags: true,
            disabled: true
        }
    }

    openArrowicon = false

    @Watch('propPhoneNumber')
    majVueTelInputPhoneNumber () {
        this.initVueTelInputReadOnly()
        this.majVueTelInputModel()
    }

    @Watch('propInputReadOnly')
    majVueTelInputReadOnly () {
        this.initVueTelInputReadOnly()
        this.majVueTelInputModel()
    }

    beforeMount () {
        this.initVueTelInputReadOnly()
        this.inputPhoneNumber = this.$props.propPhoneNumber
    }

    majVueTelInputModel () {
        this.inputPhoneNumber = this.$props.propPhoneNumber
    }

    initVueTelInputReadOnly () {
        this.vueTelInputProps.inputOptions.readonly = this.$props.propInputReadOnly
        this.vueTelInputProps.inputOptions.styleClasses = this.$props.propInputReadOnly ? 'form-control-plaintext form-control vuetelinput_class' : 'form-control vuetelinput_class'
        this.inputBaseClass = this.vueTelInputProps.inputOptions.styleClasses
        this.vueTelInputProps.dropdownOptions.disabled = this.$props.propDropdownDisabled
    }

    /** Ne sert pas à priori finalement (pour l'instant) */
    initVueTelInputValidClass (valid: boolean) {
        this.vueTelInputProps.inputOptions.styleClasses = (valid ? this.inputBaseClass : this.inputBaseClass + ' is-invalid')
    }

    onDropdownOpen (valeur: boolean) {
        this.openArrowicon = valeur
    }

    onDropdownClose (valeur: boolean) {
        this.openArrowicon = valeur
    }

    vueTelInputEvent (number: string, phoneObject: any) {
        this.infosPhoneInput.formattedPhoneNumber = phoneObject.number
        this.infosPhoneInput.phoneNumber = this.inputPhoneNumber

        if (phoneObject.valid) {
            // console.log('From vueTelInputEvent => VALIDE')
            this.isInvalid = ''
            // this.initVueTelInputValidClass(true)
            this.infosPhoneInput.isValidatedPhoneNumber = true
        } else {
            // console.log('From vueTelInputEvent => INVALIDE')
            this.isInvalid = (this.inputPhoneNumber === '' && phoneObject.number === undefined && this.vueTelInputProps.inputOptions.required === false) ? 'vuetelinput_invalid_empty' : 'vuetelinput_invalid'
            // this.initVueTelInputValidClass(false)
            this.infosPhoneInput.isValidatedPhoneNumber = false
        }
    }

    vueTelValidateEvent (phoneObject: any) {
        this.infosPhoneInput.phoneNumber = this.inputPhoneNumber
        if (phoneObject.valid) {
            // console.log('From vueTelInputEvent => VALIDE')
            this.isInvalid = ''
            // this.initVueTelInputValidClass(true)
            this.infosPhoneInput.isValidatedPhoneNumber = true
        } else {
            // console.log('From vueTelInputEvent => INVALIDE')
            this.isInvalid = (this.inputPhoneNumber === '' && phoneObject.number === undefined && this.vueTelInputProps.inputOptions.required === false) ? 'vuetelinput_invalid_empty' : 'vuetelinput_invalid'
            // this.initVueTelInputValidClass(false)
            this.infosPhoneInput.isValidatedPhoneNumber = false
        }
    }

    vueTelCountryChangeEvent (countryObject: any) {
        this.infosPhoneInput.formattedPhoneNumber = countryObject.number
        this.infosPhoneInput.phoneNumber = this.inputPhoneNumber

        if (countryObject.valid) {
            // console.log('From vueTelCountryChangeEvent => VALIDE')
            this.isInvalid = ''
            // this.initVueTelInputValidClass(true)
            this.infosPhoneInput.isValidatedPhoneNumber = true
        } else {
            // console.log('From vueTelCountryChangeEvent => INVALIDE')
            this.isInvalid = (this.inputPhoneNumber === '' && countryObject.number === undefined && this.vueTelInputProps.inputOptions.required === false) ? 'vuetelinput_invalid_empty' : 'vuetelinput_invalid'
            // this.initVueTelInputValidClass(false)
            this.infosPhoneInput.isValidatedPhoneNumber = false
        }
    }
}
