



































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { formatDate } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getEtatSpec as getEtatSpecAcad } from '@/types/DossierAcademique'
import { Etat, getEtatSpec as getEtatSpecAdm, getEtatSpecContrat } from '@/types/DossierAdministratif'
import { Ability } from '@/types/Ability'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import { BModal } from 'bootstrap-vue'


@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        PhoneInput,
        'b-modal': BModal
    }
})

export default class PopUpInfoIntervenant extends Vue {
    @Prop() showPopup?: boolean
    @Prop() user_id?: any
    @Prop() session_id?: any

    show                        = false
    ready                       = false
    error                       = null
    isWaiting                   = false

    data_intervenant: any = {}

    getEtatSpecAcad = getEtatSpecAcad
    getEtatSpecAdm = getEtatSpecAdm
    getEtatSpecContrat = getEtatSpecContrat
    formatDate = formatDate
    Etat = Etat


    @Watch('showPopup')
    initPopup () {
        if (this.$store.getters['auth/can'](Ability.RH_SOL_VIEW) || this.$store.getters['auth/can'](Ability.RH_DOSADM_VIEW) || this.$store.getters['auth/can'](Ability.GC_CENTER_VIEW)  || this.$store.getters['auth/can'](Ability.RH_SPS_VIEW)) {
            if (this.showPopup === true) {
                this.error = null
                this.load()
            }
        } else {
            this.closePopup()
        }
    }

    closePopup() {
        this.$emit('close')
    }

    getEtatContratsSignGlobal () {
        let etatGlobal = 3
        if (this.data_intervenant && this.data_intervenant.contrats && this.data_intervenant.contrats.length > 0) {
            for (let i =  0; i < this.data_intervenant.contrats.length; i++) {
                if (this.data_intervenant.contrats[i].etat < etatGlobal) {
                    etatGlobal = this.data_intervenant.contrats[i].etat
                }
            }
            return this.getEtatSpecContrat(etatGlobal)
        } else {
            return  this.getEtatSpecContrat(0)
        }
    }

    // Retourne des états formattés spécial demande de remboursement
    getEtat (etat: any, type: string) {
        switch (etat) {
            case Etat.ETAT_DEPOSE:
                return type === 'f' ? 'Demandée' : 'Demandé'
            case Etat.ETAT_VALIDE:
                return type === 'f' ? 'Acceptée' : 'Accepté'
            case Etat.ETAT_REJETE:
                return type === 'f' ? 'Refusée' : 'Refusé'
        }
    }

    load () {
        this.ready = false
        this.$store.dispatch('user/getFicheIntervenants', { session_id: this.session_id, user_id: this.user_id }).then((resp: any) => {
            this.data_intervenant = resp.data.data
            this.ready = true
        }).catch((error: any) => {
            console.log('ko:' + error)
        })
    }
}
