
















































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters } from 'vuex'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import moment from 'moment-timezone'


import 'vue-datetime/dist/vue-datetime.css'
import { diffTwoDatesInDays, formatDateSinTime,  time_convert, hoursMinutesToGmt, minutes_convert, hoursMinutesToLocal, setHourMinuteToDate, formatDateYYYYMMDD, getUnixTime, getWeekDayOfTime, addDaysToDate, formatDayHourZDateFromMoment, hoursMinutesToDateTime, diffTwoDatesInMinutes } from '@/utils/helpers'

@Component({
    components: {
        VueTimepicker,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('serie', ['series']),
        ...mapGetters('epreuve', ['error']),
        ...mapGetters('examinateur', ['error_exa'])
    }
})

export default class OrganisationJourneesOral extends Vue {
    @Prop() epreuve?: any;
    @Prop() currentSession?: any;

    @Prop() mode_personalisation?: boolean;

    @Prop() h_perso?:any;


    Ability = Ability
    pause_actif = false
    formatDateSinTime = formatDateSinTime
    selectedSerieId = 0
    epreuveSeries: {[serieId: number]: any} = {}
    journees_type: {[indice: number]: { debut_prep: string; debut_oral: string; fin_oral: string}} = {}
    seriesDays: {[serieId: number]: any} = {}
    nbr_jour_minimum = 7
    h_debut = ''
    h_fin = ''
    h_pause_debut = ''
    h_pause_fin = ''
    h_start_min: any = null
    h_end_max: any = null
    count_indice = 0
    selectedSerie_NbrJours = 0
    erreur_serieVideDeJour: any = null
    is_configurable_schedule = false
    no_initial_pause = false

    MessageErreurSimple = 'Enregistrement impossible :'

    serieFiltered (series: any) {
        return series.filter((se: any) => se.debut !== null)
    }

    canEdit () {
        if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession && this.currentSession.closed_at === null) {
            return true
        } else if(this.mode_personalisation){
            return true
        } else {
            return false
        }
    }

    resetJourneeType () {
        if(this.mode_personalisation) {
            this.h_perso.h_start = ''
            this.h_perso.h_end = ''
            this.h_perso.h_start_break = ''
            this.h_perso.h_end_break = ''

            this.h_debut = this.epreuve.h_debut
            this.h_fin = this.epreuve.h_fin
            this.h_pause_debut = this.epreuve.h_pause_debut
            this.h_pause_fin = this.epreuve.h_pause_fin
            this.pause_actif = false
            if (this.h_pause_debut && this.h_pause_fin) {
                this.pause_actif = true
            }
            this.key_random = Math.random()
        }
    }

    nbCandJourChangeHandler(e: any) {
        // gestion du champ vide. On gère via le v-model car ne marchera pas avec la value.
        if (e.target.value === '') {
            if (e.target.id.includes('nb_cand_jour')) {
                this.epreuve.nb_cand_jour = 1
            } else if (e.target.id.includes('nb_cand_demi_jour')) {
                this.epreuve.nb_cand_demi_jour = 1
            } else if (e.target.id.includes('nb_cand_vacation')) {
                this.epreuve.nb_cand_vacation = 1
            }
        } else {
            // On reformate la valeur en entier au cas où (cas des 0 devant le chiffre par exemple)
            if (e.target.id.includes('nb_cand_jour')) {
                this.epreuve.nb_cand_jour = parseInt(e.target.value, 10)
                // gestion des valeurs négatives
                if (this.epreuve.nb_cand_jour < 0) {
                    this.epreuve.nb_cand_jour = this.epreuve.nb_cand_jour * -1
                }
                // gestion des valeurs > 99
                if (this.epreuve.nb_cand_jour > 99) {
                    this.epreuve.nb_cand_jour = 99
                }
            } else if (e.target.id.includes('nb_cand_demi_jour')) {
                this.epreuve.nb_cand_demi_jour = parseInt(e.target.value, 10)
                // gestion des valeurs négatives
                if (this.epreuve.nb_cand_demi_jour < 0) {
                    this.epreuve.nb_cand_demi_jour = this.epreuve.nb_cand_demi_jour * -1
                }
                // gestion des valeurs > 99
                if (this.epreuve.nb_cand_demi_jour > 99) {
                    this.epreuve.nb_cand_demi_jour = 99
                }
            } else if (e.target.id.includes('nb_cand_vacation')) {
                this.epreuve.nb_cand_vacation = parseInt(e.target.value, 10)
                // gestion des valeurs négatives
                if (this.epreuve.nb_cand_vacation < 0) {
                    this.epreuve.nb_cand_vacation = this.epreuve.nb_cand_vacation * -1
                }
                // gestion des valeurs > 99
                if (this.epreuve.nb_cand_vacation > 99) {
                    this.epreuve.nb_cand_vacation = 99
                }
            }
        }
    }

    nbChevauchementJourChangeHandler(e: any) {
        // gestion du champ vide. On gère via le v-model car ne marchera pas avec la value.
        if (e.target.value === '') {
            if (e.target.id.includes('jour_chevauchement_avant')) {
                this.epreuve.jour_chevauchement_avant = 0
            } else if (e.target.id.includes('jour_chevauchement_apres')) {
                this.epreuve.jour_chevauchement_apres = 0
            }
        } else {
            // On reformate la valeur en entier au cas où (cas des 0 devant le chiffre par exemple)
            if (e.target.id.includes('jour_chevauchement_avant')) {
                this.epreuve.jour_chevauchement_avant = parseInt(e.target.value, 10)
                // gestion des valeurs négatives
                if (this.epreuve.jour_chevauchement_avant < 0) {
                    this.epreuve.jour_chevauchement_avant = this.epreuve.jour_chevauchement_avant * -1
                }
                // gestion des valeurs > 2
                if (this.epreuve.jour_chevauchement_avant > 2) {
                    this.epreuve.jour_chevauchement_avant = 2
                }
            } else if (e.target.id.includes('jour_chevauchement_apres')) {
                this.epreuve.jour_chevauchement_apres = parseInt(e.target.value, 10)
                // gestion des valeurs négatives
                if (this.epreuve.jour_chevauchement_apres < 0) {
                    this.epreuve.jour_chevauchement_apres = this.epreuve.jour_chevauchement_apres * -1
                }
                // gestion des valeurs > 2
                if (this.epreuve.jour_chevauchement_apres > 2) {
                    this.epreuve.jour_chevauchement_apres = 2
                }
            }
        }
    }

    /** return la valeur heureMinute à l'heure local */
    formatHeureMinute (heureMinute: string) {
        if (heureMinute !== null) {
            return hoursMinutesToLocal(heureMinute)
        }
    }

    /** met à jour suite au changement sur la checkbox de l'activation de la pause */
    pauseActifHandler () {
        this.pause_actif = !this.pause_actif
        if (!this.pause_actif) {
            this.h_pause_debut = ''
            this.h_pause_fin = ''
        }
        this.journeeTypeGenerator()
    }

    /** met à jour suite au changement sur la checkbox de l'autorisation de la personalisation */
    isConfigurableScheduleHandler () {
        this.is_configurable_schedule = !this.is_configurable_schedule
        if (!this.is_configurable_schedule) {
            this.h_start_min = null
            this.h_end_max = null
            this.journeeTypeGenerator()
            this.key_random = Math.random()
        }
    }

    key_random = 1
    /** met à jour les heures debut, fin, debut pause et fin pause suite à un changement sur les timePickers */
    timePickerHandler(event: any, type: string) {
        this.$store.state.epreuve.error = null
        this.$store.state.examinateur.error = null
        const dayOne = this.$store.state.serie.series[0].debut
        switch (type) {
            case 'h_debut':
                if(this.mode_personalisation && moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_start_min))) {
                    this.h_debut = this.h_start_min
                } else {
                    if(this.pause_actif && moment(hoursMinutesToDateTime(event.displayTime,dayOne)).isAfter(moment(this.h_pause_debut))){
                        this.h_debut = this.h_pause_debut
                    } else if (moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isAfter(moment(this.h_fin))) {
                        this.h_debut = this.h_fin
                    } else {
                        this.h_debut = hoursMinutesToDateTime(event.displayTime, dayOne)
                    }
                }
                break
            case 'h_fin':
                if(this.mode_personalisation &&  moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isAfter(moment(this.h_end_max))) {
                    this.h_fin = this.h_end_max
                } else {
                    if(this.pause_actif && moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_pause_fin))){
                        this.h_fin = this.h_pause_fin
                    } else if (moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_debut))) {
                        this.h_fin = this.h_debut
                    } else {
                        this.h_fin = hoursMinutesToDateTime(event.displayTime, dayOne)
                    }
                }
                break
            case 'h_pause_debut':
                if (moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_debut))) {
                    this.h_pause_debut = this.h_debut
                } else if(moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isAfter(moment(this.h_pause_fin))) {
                    this.h_pause_debut = formatDayHourZDateFromMoment(moment(this.h_pause_fin).utc().subtract(20, "minutes"))
                } else if(moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isAfter(moment(this.h_fin))){
                    this.h_pause_debut = formatDayHourZDateFromMoment(moment(this.h_pause_fin).utc().subtract(20, "minutes"))
                } else {
                    if(diffTwoDatesInMinutes(hoursMinutesToDateTime(event.displayTime, dayOne), this.h_pause_fin) < 20) {
                        this.h_pause_debut = formatDayHourZDateFromMoment(moment(this.h_pause_fin).utc().subtract(20, "minutes"))
                    } else {
                        this.h_pause_debut = hoursMinutesToDateTime(event.displayTime, dayOne)
                    }
                }
                break
            case 'h_pause_fin':
                if (moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isAfter(moment(this.h_fin))) {
                    this.h_pause_fin = this.h_fin
                } else if(moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_pause_debut))) {
                    this.h_pause_fin = formatDayHourZDateFromMoment(moment(this.h_pause_debut).utc().add(20, "minutes"))
                } else if(this.pause_actif && moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_debut))){
                    this.h_pause_fin = this.h_debut
                } else {
                    if(diffTwoDatesInMinutes(this.h_pause_debut, hoursMinutesToDateTime(event.displayTime, dayOne)) < 20) {
                        this.h_pause_fin = formatDayHourZDateFromMoment(moment(this.h_pause_debut).utc().add(20, "minutes"))
                    } else {
                        this.h_pause_fin = hoursMinutesToDateTime(event.displayTime, dayOne)
                    }
                }
                break
            case 'h_start_min':
                if(moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isAfter(moment(this.h_debut))){
                    this.h_start_min =  this.h_debut

                } else {
                    this.h_start_min = hoursMinutesToDateTime(event.displayTime, dayOne)
                }
                break
            case 'h_end_max':
                if(moment(hoursMinutesToDateTime(event.displayTime, dayOne)).isBefore(moment(this.h_fin))){
                    this.h_end_max = this.h_fin

                } else {
                    this.h_end_max = hoursMinutesToDateTime(event.displayTime, dayOne)
                }
                break
        }
        this.key_random = Math.random()
        this.journeeTypeGenerator()
    }

    /** met à jour les series de l'épreuve */
    updateSeriesEpreuve () {
        this.epreuve.series = []
        this.nbr_jour_minimum = 7 // compteur du nombre de jours cochés minimum sur une série. On part du nombre max possible sur une semaine.
        for (let i = 0; i < this.$store.state.serie.series.length; i++) {
            if(this.$store.state.serie.series[i].debut) {
                let nbr_jour_serie_temp = 0 // compteur du nombre de jour cochés pour la série
                for (const dayId in this.seriesDays[this.$store.state.serie.series[i].id]) {
                    const day = this.seriesDays[this.$store.state.serie.series[i].id][dayId]
                    if (this.epreuveSeries[this.$store.state.serie.series[i].id] && this.epreuveSeries[this.$store.state.serie.series[i].id][day.id]) {
                        nbr_jour_serie_temp++

                        const h_debut = setHourMinuteToDate(day.date, hoursMinutesToGmt(this.epreuve.h_debut))
                        const h_fin = setHourMinuteToDate(day.date, hoursMinutesToGmt(this.epreuve.h_fin))

                        const paramsTemp: any = {
                            is_configurable_schedule: this.is_configurable_schedule ? 1 : 0,
                            serie_id: this.$store.state.serie.series[i].id,
                            jour: formatDateYYYYMMDD(day.date), // '2021-12-14'
                            h_debut: formatDayHourZDateFromMoment(h_debut), // '2021-12-14 09:00:00'
                            h_fin: formatDayHourZDateFromMoment(h_fin), // , // '2021-12-14 18:00:00
                            h_pause_debut: null, // '2021-12-14 13:00:00
                            h_pause_fin: null,  // '2021-12-14 15:00:00'
                            h_start_min: null, // '2021-12-14 09:00:00'
                            h_end_max: null // , // '2021-12-14 18:00:00
                        }

                        if (this.is_configurable_schedule && this.h_start_min && this.h_end_max && this.h_start_min !== '' && this.h_end_max !== '') {
                            paramsTemp.h_start_min = formatDayHourZDateFromMoment(setHourMinuteToDate(day.date, hoursMinutesToGmt(this.epreuve.h_start_min)))
                            paramsTemp.h_end_max = formatDayHourZDateFromMoment(setHourMinuteToDate(day.date, hoursMinutesToGmt(this.epreuve.h_end_max)))
                        }

                        if (this.pause_actif && this.h_pause_debut !== '' && this.h_pause_fin !== '') {
                            paramsTemp.h_pause_debut = formatDayHourZDateFromMoment(setHourMinuteToDate(day.date, hoursMinutesToGmt(this.epreuve.h_pause_debut)))
                            paramsTemp.h_pause_fin = formatDayHourZDateFromMoment(setHourMinuteToDate(day.date, hoursMinutesToGmt(this.epreuve.h_pause_fin)))
                        }

                        this.epreuve.series.push(paramsTemp)

                    }
                }

                // maj du check du compteur du nombre de jours cochés minimum sur une série
                if (nbr_jour_serie_temp < this.nbr_jour_minimum) {
                    this.nbr_jour_minimum = nbr_jour_serie_temp
                }
            }
        }
    }

    /** met à jours les info sur l'objet épreuve et regénère l'aperçu de la journée type */
    journeeTypeGenerator() {
        if (this.h_debut && this.h_fin) {
            if(!this.mode_personalisation) {
                this.epreuve.h_debut = this.h_debut
                this.epreuve.h_fin = this.h_fin

                this.epreuve.h_pause_debut = this.h_pause_debut
                this.epreuve.h_pause_fin = this.h_pause_fin

                this.epreuve.h_start_min = this.h_start_min
                this.epreuve.h_end_max = this.h_end_max
                this.epreuve.is_configurable_schedule = this.is_configurable_schedule ? 1 : 0
            } else {
                if( this.h_debut === this.epreuve.h_debut && this.h_fin === this.epreuve.h_fin && this.h_pause_debut === this.epreuve.h_pause_debut && this.h_pause_fin  === this.epreuve.h_pause_fin) {
                    this.h_perso.h_start = null
                    this.h_perso.h_end = null
                    this.h_perso.h_start_break = null
                    this.h_perso.h_end_break = null
                } else {
                    this.h_perso.h_start = this.h_debut
                    this.h_perso.h_end = this.h_fin
                    this.h_perso.h_start_break = this.h_pause_debut
                    this.h_perso.h_end_break = this.h_pause_fin
                }
            }

            this.journees_type = {}
            this.count_indice = 0
            const h_debut = hoursMinutesToGmt(this.h_debut)
            const h_fin = hoursMinutesToGmt(this.h_fin)
            const h_pause_debut = hoursMinutesToGmt(this.h_pause_debut)
            const h_pause_fin = hoursMinutesToGmt(this.h_pause_fin)
            if (parseInt(this.epreuve.duree_composition) !== 0 && h_debut && h_fin) {
                let currentDebutOral = minutes_convert(h_debut)  + parseInt(this.epreuve.duree_preparation)
                const finOral = minutes_convert(h_fin)
                let indice = 1
                while (currentDebutOral + parseInt(this.epreuve.duree_composition) <= finOral) {
                    if ((h_pause_debut === '' && h_pause_fin === '') ||
                    ((currentDebutOral < minutes_convert(h_pause_debut) && (currentDebutOral + parseInt(this.epreuve.duree_composition)) <= minutes_convert(h_pause_debut)) ||
                    ((currentDebutOral - parseInt(this.epreuve.duree_preparation)) >= minutes_convert(h_pause_fin)))
                    ) {
                        this.journees_type[indice] = {
                            debut_prep: hoursMinutesToLocal(time_convert(currentDebutOral - parseInt(this.epreuve.duree_preparation))),
                            debut_oral: hoursMinutesToLocal(time_convert(currentDebutOral)),
                            fin_oral: hoursMinutesToLocal(time_convert(currentDebutOral + parseInt(this.epreuve.duree_composition)))
                        }
                        this.count_indice++
                        indice++
                        currentDebutOral += parseInt(this.epreuve.duree_composition)
                    } else {
                        currentDebutOral = minutes_convert(h_pause_fin) + parseInt(this.epreuve.duree_preparation)
                    }
                }
            }
            if(!this.mode_personalisation) {
                this.checkDateHandler()
            }
        }
    }

    checkDateHandler_event() {
        // const el = e.target as HTMLInputElement
        /*
        if (this.selectedSerie_NbrJours < 2 && el.checked === false) {
            // on ne doit pas avoir de série vide de jour, on force la valeur à cocher et on affiche l'info d'erreur
            this.erreur_serieVideDeJour = 'Une série ne peux pas être vide de jour.'
            el.checked = true
        } else {
            */
        this.erreur_serieVideDeJour = null
        const checks = this.$el.querySelectorAll('.checkDate')
        this.epreuveSeries[this.selectedSerieId] = []
        for (let i = 0; i < checks.length; i++) {
            if ((checks[i] as HTMLInputElement).checked) {
                if (!this.epreuveSeries[this.selectedSerieId][checks[i].id]) {
                    this.epreuveSeries[this.selectedSerieId][checks[i].id] = true
                }
            }
        }
        this.updateSeriesEpreuve()
        this.initSelectedSerieNbrJours()
        // }
    }

    /** actualise la selection des series */
    checkDateHandler() {
        const checks = this.$el.querySelectorAll('.checkDate')
        this.epreuveSeries[this.selectedSerieId] = []
        for (let i = 0; i < checks.length; i++) {
            if ((checks[i] as HTMLInputElement).checked) {
                if (!this.epreuveSeries[this.selectedSerieId][checks[i].id]) {
                    this.epreuveSeries[this.selectedSerieId][checks[i].id] = true
                }
            }
        }
        this.updateSeriesEpreuve()
        this.initSelectedSerieNbrJours()
    }

    initSelectedSerieNbrJours () {
        this.selectedSerie_NbrJours = 0
        for (const dayIndex in this.seriesDays[this.selectedSerieId]) {
            if (this.epreuveSeries && this.epreuveSeries[this.selectedSerieId] && this.epreuveSeries[this.selectedSerieId][dayIndex] === true) {
                this.selectedSerie_NbrJours++
            }
        }
        // console.log('this.selectedSerie_NbrJours :', this.selectedSerie_NbrJours)
    }

    /** construit le contenu des series */
    buildSeriesDays () {
        this.seriesDays = {}
        for (let i = 0; i < this.$store.state.serie.series.length; i++) {
            if(this.$store.state.serie.series[i].debut) {
                this.seriesDays[this.$store.state.serie.series[i].id] = {}
                const CurrentFirstDayFormatted = formatDateSinTime(this.$store.state.serie.series[i].debut)
                const currentFirstDayString =  CurrentFirstDayFormatted.split('/')[0] + '/' + CurrentFirstDayFormatted.split('/')[1] + ' ' + getWeekDayOfTime(this.$store.state.serie.series[i].debut)
                this.seriesDays[this.$store.state.serie.series[i].id][getUnixTime(this.$store.state.serie.series[i].debut) + '_' + this.$store.state.serie.series[i].id] = {
                    id: getUnixTime(this.$store.state.serie.series[i].debut) + '_' + this.$store.state.serie.series[i].id,
                    day: currentFirstDayString,
                    date : this.$store.state.serie.series[i].debut,
                    serie: this.$store.state.serie.series[i],
                    checked: false
                }
                const daysDiff = diffTwoDatesInDays(this.$store.state.serie.series[i].debut, this.$store.state.serie.series[i].fin)
                let currentDay = this.$store.state.serie.series[i].debut
                for (let j = 0; j < daysDiff; j++) {
                    currentDay = addDaysToDate(currentDay, 1)
                    const constCurrentDayFormatted =  formatDateSinTime(currentDay)
                    const currentDayString =  constCurrentDayFormatted.split('/')[0] + '/' + constCurrentDayFormatted.split('/')[1] + ' ' + getWeekDayOfTime(currentDay)
                    this.seriesDays[this.$store.state.serie.series[i].id][getUnixTime(currentDay) + '_' + this.$store.state.serie.series[i].id] = {
                        id: getUnixTime(currentDay) + '_' + this.$store.state.serie.series[i].id,
                        day: currentDayString,
                        date: currentDay,
                        serie: this.$store.state.serie.series[i],
                        checked: false
                    }
                }
            }
        }
    }

    load() {
        if(!this.epreuve){
            return
        }
        this.$store.state.epreuve.error = null
        this.buildSeriesDays()
        if(this.h_perso &&
        this.h_perso.h_start &&
        this.h_perso.h_start !== '' &&
        this.h_perso.h_end &&
        this.h_perso.h_end !== '' &&
        this.h_perso.h_start_break &&
        this.h_perso.h_start_break !== '' &&
        this.h_perso.h_end_break &&
        this.h_perso.h_end_break !== ''
        ){
            if(this.h_perso.h_start){
                this.h_perso.h_start = this.$store.state.serie.series[0].debut +'T'+ this.h_perso.h_start.split('T')[1]
            }
            if(this.h_perso.h_end){
                this.h_perso.h_end = this.$store.state.serie.series[0].debut +'T'+ this.h_perso.h_end.split('T')[1]
            }
            if(this.h_perso.h_start_break){
                this.h_perso.h_start_break = this.$store.state.serie.series[0].debut +'T'+ this.h_perso.h_start_break.split('T')[1]
            }
            if(this.h_perso.h_end_break){
                this.h_perso.h_end_break = this.$store.state.serie.series[0].debut +'T'+ this.h_perso.h_end_break.split('T')[1]
            }
        } else if(this.h_perso) {
            this.h_perso.h_start = null
            this.h_perso.h_end = null
            this.h_perso.h_start_break = null
            this.h_perso.h_end_break = null

        }
        if(this.epreuve.h_debut){
            this.epreuve.h_debut = this.$store.state.serie.series[0].debut +'T'+ this.epreuve.h_debut.split('T')[1]
        }
        if(this.epreuve.h_end_max){
            this.epreuve.h_end_max = this.$store.state.serie.series[0].debut +'T'+ this.epreuve.h_end_max.split('T')[1]
        }
        if(this.epreuve.h_fin){
            this.epreuve.h_fin = this.$store.state.serie.series[0].debut +'T'+ this.epreuve.h_fin.split('T')[1]
        }
        if(this.epreuve.h_pause_debut){
            this.epreuve.h_pause_debut = this.$store.state.serie.series[0].debut +'T'+ this.epreuve.h_pause_debut.split('T')[1]
        }
        if(this.epreuve.h_pause_fin){
            this.epreuve.h_pause_fin = this.$store.state.serie.series[0].debut +'T'+ this.epreuve.h_pause_fin.split('T')[1]
        }
        if(this.epreuve.h_start_min){
            this.epreuve.h_start_min = this.$store.state.serie.series[0].debut +'T'+ this.epreuve.h_start_min.split('T')[1]
        }
        if(this.mode_personalisation) {
            this.no_initial_pause = true
            if(this.epreuve.h_pause_debut && this.epreuve.h_pause_fin) {
                this.no_initial_pause = false
            }

            if(this.h_perso && this.h_perso.h_start) {

                this.h_debut = this.h_perso.h_start
                this.h_fin = this.h_perso.h_end

                if (this.h_perso.h_start_break && this.h_perso.h_end_break) {
                    this.pause_actif = true
                    this.h_pause_debut = this.h_perso.h_start_break
                    this.h_pause_fin = this.h_perso.h_end_break
                } else {
                    this.pause_actif = false
                }
            } else {
                this.h_debut = this.epreuve.h_debut
                this.h_fin = this.epreuve.h_fin

                if (this.epreuve.h_pause_debut && this.epreuve.h_pause_fin) {
                    this.pause_actif = true
                    this.h_pause_debut = this.epreuve.h_pause_debut
                    this.h_pause_fin = this.epreuve.h_pause_fin
                } else {
                    this.pause_actif = false
                }
            }
        } else {
            this.h_debut = this.epreuve.h_debut
            this.h_fin = this.epreuve.h_fin
            if (this.epreuve.h_pause_debut && this.epreuve.h_pause_fin) {
                this.pause_actif = true
                this.h_pause_debut = this.epreuve.h_pause_debut
                this.h_pause_fin = this.epreuve.h_pause_fin
            } else {
                this.pause_actif = false
            }

        }
        if(this.epreuve.is_configurable_schedule === 1) {
            this.is_configurable_schedule = true
            this.h_start_min = this.epreuve.h_start_min
            this.h_end_max = this.epreuve.h_end_max
        } else {
            this.is_configurable_schedule = false
            this.h_start_min = null
            this.h_end_max = null
        }
        this.epreuveSeries = {}
        for (let i = 0; i < this.epreuve.series.length; i++) {
            const dayId = getUnixTime(this.epreuve.series[i].jour) + '_' + this.epreuve.series[i].serie_id
            if (!this.epreuveSeries[this.epreuve.series[i].serie_id]) {
                this.epreuveSeries[this.epreuve.series[i].serie_id] = {}
            }
            this.epreuveSeries[this.epreuve.series[i].serie_id][dayId] = true
        }

        this.journeeTypeGenerator()
        if (this.$store.state.serie.series[0]) {
            this.selectedSerieId = this.$store.state.serie.series[0].id
            this.initSelectedSerieNbrJours()
        }
    }

    mounted () {
        this.load()
    }
}
