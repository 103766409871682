













import { Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { MessageIndicationType } from '@/types/MessageIndicationType'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class MessageGeneralIntervenant extends Vue
{
    showDismissibleAlert = true
    MessageIndicationType = MessageIndicationType
}
