


























































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'
import { base64ToArrayBuffer, formatDate, formatDateSinTime, getFileNameFromHeader, isObject } from '@/utils/helpers'
import { EtatCreneau, EtatDispo } from '@/types/Disponibilites'
import { TypeEnsemble } from '@/types/Ensemble'
import { Ability } from '@/types/Ability'
import Semainier from '@/components/GestionExaminateurs/Disponibilites/Semainier.vue'
import SemainierTP from '@/components/GestionExaminateurs/Disponibilites/SemainierTP.vue'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { EntityType, getEntityTypeShortFormat, PosteType } from '@/types/Poste'
import MessageGeneralIntervenant from '@/components/MessageGeneralIntervenant.vue'
import VuePdfApp from 'vue-pdf-app'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import OrganisationJourneesOral from '@/views/Administration/Sessions/OrganisationJourneesOral.vue'
import { BModal } from 'bootstrap-vue'

@Component({
    computed: {
        ...mapState('disponibilite', ['error_dispos', 'compteurs_series_journees', 'journees_inactive', 'pause_count', 'creneaux_examinateur', 'creneaux_prio', 'creneaux_non_prio', 'creneaux_actifs', 'lock_creneau', 'messages_regles_journees', 'loading_interface', 'loading', 'candidats_reels', 'candidats_reels_total']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('examinateur', ['examinateur_select']),
        ...mapState('examinateur', ['examinateurs_equipes']),
        ...mapState('serie', ['series']),
        ...mapState('sessionuser', ['error_session_user']),
        ...mapState('sessionuser', ['sessionUser']),
        ...mapGetters('disponibilite', ['get_priorite_next', 'get_priorite_total', 'get_compteurs_serie_journee', 'check_regles_creneaux'])
    },
    components: {
        'b-modal': BModal,
        'font-awesome-icon': FontAwesomeIcon,
        Semainier,
        SemainierTP,
        ErrorDisplay,
        MessageGeneralIntervenant,
        VuePdfApp,
        OrganisationJourneesOral
    }
})

export default class GestionDisponibilites extends Vue {
    @Prop() context?: string

    Ability = Ability
    is_open = false
    epreuve_correction: any = null
    creneaux: any = []
    selected_serie_id = ''
    selected_priority_id = EtatDispo.ETAT_PRIORITAIRE
    invert_selected_priority_id = EtatDispo.ETAT_NON_PRIORITAIRE
    formatDateSinTime = formatDateSinTime
    TypePassation = TypePassation
    EtatCreneau = EtatCreneau
    EtatDispo = EtatDispo
    todayDate = Date.now()
    is_loading_pdf = false
    showModaleSoumission = false
    showModaleValidation = false
    showModaleInvalidation = false
    showModaleAnnuleSoumission = false
    showModaleAjustements = false
    showModaleInvalidationAjustements = false
    showModaleDuplicateSerie = false
    showModaleDeleteCreneauxSerie = false
    showModalePersonaliser = false
    showDismissibleAlert = true
    MessageIndicationType = MessageIndicationType
    compteur_prio_ok = true
    compteur_no_prio_ok = true
    compteurs_manques: any = []
    readOnly = true
    commentaire = ''
    has_creneaux_all_series = true
    compteurs: Array<any> = []
    refresh_creneaux = false
    show_pdf = false
    file_name: any = null
    source_doc: any = null
    create_by_other = false
    planif_en_cours = false
    exportWorking = false
    currentGroupe = null
    compteursTp: any = null
    seriesOfEpreuvesByEnsemble: any = null
    seriesEnsemblesSelectContent: Array<any> = []
    compteursAnomalie: Array<any> = []
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    showModaleModifJourneeType = false
    enreistrementModifEnCours = false

    keyTemp = 1



    // ------- personalisation journée type

    canEdit () {
        if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) || this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN)) {
            return true
        } else {
            return false
        }
    }


    seriesTemp = null
    epreuveTemp = null
    currentSession = null
    h_perso:any = null


    closeOrganisationJourneesOral () {
        this.showModalePersonaliser = false
    }

    enregistreOrganisationJourneeOral () {
        this.showModaleModifJourneeType = true
    }

    enregistreOrganisationJourneeOralSuite () {
        this.$store.state.examinateur.error = null
        this.enreistrementModifEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if(this.h_perso.h_end_break === ''){
            this.h_perso.h_end_break = null
        }
        if(this.h_perso.h_start_break === ''){
            this.h_perso.h_start_break = null
        }

        this.$store.dispatch('examinateur/updateExaminateursDisponibilite', {
            payload: this.h_perso,
            ensemble_id:  parseInt(this.selected_serie_id.split('_')[0]),
            user_id: this.$store.getters['examinateur/examinateur_select_infos'].id,
            serie_id: parseInt(this.selected_serie_id.split('_')[1])
        }).then((response) => {

            this.keyTemp = Math.random()
            if(this.context === 'examinateur') {
                this.$store.dispatch('examinateur/getDisponibilitesExaminateur').then(() => {
                    this.$store.state.examinateur.selectedExaminateur = response.data.data
                    this.selectedSerieIdChangeHandler()
                    // this.initDatas()
                    this. enregistrerOrganisationJourneeOralFin(idInfo)
                })

            } else {
                this.$emit('refresh',true)
                this. enregistrerOrganisationJourneeOralFin(idInfo)
            }

        }).catch((error) => {
            console.log('ko:' + error)
            this.$bvToast.hide(idInfo)
            this.showModaleModifJourneeType = false
            this.enreistrementModifEnCours = false
        })

        // this.showModalePersonaliser = false
        // examinateurs_disponibilites/{user}/ensembles/{ensemble}/series/{serie}/update
    }

    enregistrerOrganisationJourneeOralFin(idInfo: any) {
        this.$bvToast.hide(idInfo)
        const idSucces = 't_succes_' + Math.random()
        const succesToaster = {
            id: idSucces,
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true,
            fade: true,
            autoHideDelay: 5000
        }
        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
        this.showModaleModifJourneeType = false
        this.showModalePersonaliser = false
        this.enreistrementModifEnCours = false
    }

    // ---------------------------------------

    /**
     * @description Met à jour le style en fonction du type de passation
     * @returns {void}
     */
    @Watch('epreuve_correction')
    cssVar(): void {
        if (this.epreuve_correction && this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) {
            document.documentElement.style.setProperty('--gtc', '195px')
        } else {
            document.documentElement.style.setProperty('--gtc', '285px')
        }
    }

    /**
     * @description Charge les données au changement de série
     * @param {number} ensemble_id_temp - Id de l'ensemble
     * @param {number} serie_id_temp - Id de la série
     * @returns {any}
     */
    @Watch('selected_serie_id', { immediate: true, deep: true })
    selectedSerieIdChangeHandler(ensemble_id_temp = -1, serie_id_temp = -1): any {
        let returnEpreuve = true
        if (isNaN(ensemble_id_temp)) {
            returnEpreuve = false
            ensemble_id_temp = parseInt(this.selected_serie_id.split('_')[0])
            serie_id_temp = parseInt(this.selected_serie_id.split('_')[1])
        }
        if (this.$store.getters['examinateur/examinateur_select_infos']) {
            this.$store.dispatch('disponibilite/getCandidatsReels', { examinateur_id: this.$store.getters['examinateur/examinateur_select_infos'].id, ensemble_id: ensemble_id_temp })
            for (let m = 0; m < this.$store.getters['examinateur/examinateur_select_infos'].ensembles.length; m++) {
                if (ensemble_id_temp === parseInt(this.$store.getters['examinateur/examinateur_select_infos'].ensembles[m].id)) {
                    let epreuveCorrectionId = null
                    // if (this.$store.getters['examinateur/examinateur_select_infos'].ensembles[m].type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    for (let j = 0; j < this.$store.getters['examinateur/examinateur_select_infos'].affectations.length; j++) {
                        if (this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].ensemble_id === ensemble_id_temp &&
                                this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].serie_id === serie_id_temp) {

                            this.h_perso = {
                                h_start: this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_start ? this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_start : '',
                                h_end: this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_end ? this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_end : '',
                                h_start_break: this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_start_break ? this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_start_break : '',
                                h_end_break: this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_end_break ? this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].h_end_break : ''
                            }

                            epreuveCorrectionId = this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].epreuve_correction_id
                            if (this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].groupeExaminateurUser) {
                                this.currentGroupe = this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].groupeExaminateurUser
                            }
                            if (this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].groupeExaminateurUser &&
                                            this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].groupeExaminateurUser.groupeExaminateur &&
                                            this.$store.getters['examinateur/examinateur_select_infos'].affectations[j].groupeExaminateurUser.groupeExaminateur.responsable_id !== this.$store.getters['examinateur/examinateur_select_infos'].id) {
                                this.readOnly = true
                            }
                        }
                    }
                    // }
                    if (epreuveCorrectionId === null  && this.$store.getters['examinateur/examinateur_select_infos'].ensembles[m].type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                        epreuveCorrectionId = this.$store.getters['examinateur/examinateur_select_infos'].affectations[0].epreuve_correction_id
                    }
                    for (let i = 0; i < this.$store.state.epreuve.epreuvesCorrections.length; i++) {
                        if (this.$store.state.epreuve.epreuvesCorrections[i].groupe_epreuve_id === this.$store.getters['examinateur/examinateur_select_infos'].ensembles[m].groupe_epreuve_id && (epreuveCorrectionId === null || epreuveCorrectionId === this.$store.state.epreuve.epreuvesCorrections[i].id)) {
                            if (returnEpreuve) {
                                this.keyTemp = Math.random()
                                return this.$store.state.epreuve.epreuvesCorrections[i]
                            } else {
                                this.keyTemp = Math.random()
                                this.epreuve_correction = this.$store.state.epreuve.epreuvesCorrections[i]
                                return
                            }
                        }
                    }
                }
            }
        }
        this.keyTemp = Math.random()
    }

    /**
     * @description Met à jour les compteurs
     * @returns {void}
     */
    @Watch('compteurs_series_journees')
    freshCompteurs(): void {
        this.compteurs = this.$store.state.disponibilite.compteurs_series_journees
    }

    /**
     * @description Met à jour les compteurs TP
     * @param {any} compteurs - Compteurs
     * @returns {void}
     */
    updateCompteursTp(compteurs: any): void {
        this.compteursTp = compteurs
    }

    /**
     * @description Vérifie si la valeur est un objet
     * @param {any} value - Valeur
     * @returns {boolean} - Résultat
     */
    isObjectLocal(value: any): boolean {
        return isObject(value)
    }

    /**
     * @description Filtration des séries en fonction des épreuves correction
     * @param {any} tempsEpreuves - Temps des épreuves
     * @param {number} ensemble_id - Id de l'ensemble
     * @returns {Array<any>} - Séries des épreuves
     */
    getSeriesOfEpreuves(tempsEpreuves: any = null, ensemble_id = 0): Array<any> {
        let first = true
        const seriesOfEpreuves: Array<any> = []
        if (!this.seriesOfEpreuvesByEnsemble) {
            this.seriesOfEpreuvesByEnsemble = {}
        }
        this.seriesOfEpreuvesByEnsemble[ensemble_id] = []
        if (tempsEpreuves === null) {
            tempsEpreuves = this.$store.state.epreuve.epreuvesCorrections
        }
        const epreuve_correction_ids =  this.$store.getters['examinateur/get_examinateur_groupe_epreuve_epreuve_epreuve_correction_ids'](this.$store.getters['examinateur/examinateur_select'])
        for (let i = 0; i < this.$store.state.serie.series.length; i++) {
            for (let j = 0; j < tempsEpreuves.length; j++) {
                if(epreuve_correction_ids.includes(tempsEpreuves[j].id)) {
                    const tempEpreuve = this.$store.state.epreuve.epreuvesCorrections.find((ec: any) => parseInt(ec.id) === parseInt(tempsEpreuves[j].id))
                    if (tempEpreuve !== undefined) {
                        const listeSerieIds: any = []
                        for (let k = 0; k < tempEpreuve.series.length; k++){
                            if (!listeSerieIds.includes(tempEpreuve.series[k].serie_id)) {
                                listeSerieIds.push(tempEpreuve.series[k].serie_id)
                            }
                        }
                        let found = false
                        for (let l = 0; l < this.$store.getters['examinateur/examinateur_select_infos'].affectations.length; l++) {
                            if (this.$store.getters['examinateur/examinateur_select_infos'].affectations[l].serie_id === this.$store.state.serie.series[i].id &&
                            listeSerieIds.includes(this.$store.state.serie.series[i].id)  &&
                            (this.$store.getters['examinateur/examinateur_select_infos'].affectations[l].remplacant_id === null || this.$store.getters['examinateur/examinateur_select_infos'].affectations[l].remplacant_id === this.$store.getters['examinateur/examinateur_select_infos'].id)) {
                                found = true
                                break
                            } 
                        }
                        if (found && (seriesOfEpreuves.filter((s: any) => s.id === this.$store.state.serie.series[i].id).length === 0)) {
                            seriesOfEpreuves.push(this.$store.state.serie.series[i])
                            this.seriesOfEpreuvesByEnsemble[ensemble_id].push(this.$store.state.serie.series[i])
                            if (first && this.selected_serie_id === '') {
                                this.selected_serie_id = ensemble_id + '_' + seriesOfEpreuves[seriesOfEpreuves.length - 1].id
                                first = false
                            }
                            break
                        }
                    }
                }
            }
        }
        return seriesOfEpreuves
    }

    /**
     * @description Récupère les séries des épreuves
     * @return {void}
     */
    getSeriesEnsemblesSelectContent(): void {
        this.selected_serie_id = ''
        this.seriesEnsemblesSelectContent = []
        const examinateurSelect = this.$store.getters['examinateur/examinateur_select']
        for (let i = 0; i < examinateurSelect.ensembles.length; i++) {
            this.seriesEnsemblesSelectContent.push({
                ensemble_id: examinateurSelect.ensembles[i].id,
                ensemble_name: examinateurSelect.ensembles[i].name,
                concour_code: examinateurSelect.ensembles[i].concour && examinateurSelect.ensembles[i].concour.code ? examinateurSelect.ensembles[i].concour.code :  '',
                series: this.getSeriesOfEpreuves(examinateurSelect.ensembles[i].groupeEpreuve.epreuves, examinateurSelect.ensembles[i].id)
            })
        }
    }

    /**
     * @description Formattage des dates
     * @param {Date} date - Date
     * @returns {string | undefined} - Date formatée
     */
    formatteDate(date: Date): string | undefined {
        return formatDate(date)
    }

    /**
     * @description Sélectionne une priorité
     * @param {any} e - Evènement
     * @returns {void}
     */
    selectPriority (e: any) {
        if (e.target.checked) {
            this.selected_priority_id = EtatDispo.ETAT_NON_PRIORITAIRE
            this.invert_selected_priority_id = EtatDispo.ETAT_PRIORITAIRE
        } else {
            this.selected_priority_id = EtatDispo.ETAT_PRIORITAIRE
            this.invert_selected_priority_id = EtatDispo.ETAT_NON_PRIORITAIRE
        }
    }

    /**
     * @description Sélectionne une priorité avec un bouton
     * @param {EtatDispo} etat - Etat de disponibilité
     * @returns {void}
     */
    selectPriorityWithButton(etat: EtatDispo): void {
        if (etat === EtatDispo.ETAT_PRIORITAIRE) {
            this.selected_priority_id = EtatDispo.ETAT_PRIORITAIRE
            this.invert_selected_priority_id = EtatDispo.ETAT_NON_PRIORITAIRE
        } else if ((etat === EtatDispo.ETAT_NON_PRIORITAIRE)) {
            this.selected_priority_id = EtatDispo.ETAT_NON_PRIORITAIRE
            this.invert_selected_priority_id = EtatDispo.ETAT_PRIORITAIRE
        }
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    async initDatas(): Promise<void> {
        const params: any = {}
        // Affichage espace examinateur
        if (this.$props.context === 'examinateur') {
            this.$store.getters['examinateur/listeExaminateurs'].push(this.$store.getters['examinateur/selectedExaminateur'])
            this.$store.commit('examinateur/SET_EXAMINATEUR_ID', this.$store.getters['examinateur/selectedExaminateur'].id)
        }

        let groupe_epreuve_id_examinateur = ''
        for (let m = 0; m < this.$store.getters['examinateur/examinateur_select_infos'].ensembles.length; m++) {
            if (this.selected_serie_id === '' || (this.selected_serie_id !== '' && parseInt(this.selected_serie_id.split('_')[0]) === this.$store.getters['examinateur/examinateur_select_infos'].ensembles[m].id)) {
                if (groupe_epreuve_id_examinateur !== '') {
                    groupe_epreuve_id_examinateur += ','
                }
                groupe_epreuve_id_examinateur += this.$store.getters['examinateur/examinateur_select_infos'].ensembles[m].groupe_epreuve_id
            }
        }

        params['filter-groupe_epreuve_id'] = '[' + groupe_epreuve_id_examinateur + ']' // groupe_epreuve_id_examinateur // TODO : pb lorsque plusieurs équipes
        params.typePassation = TypePassation.TYPE_PASSATION_ORAL

        // Chargement du session user pour affichage des états de validation
        this.$store.dispatch('sessionuser/getSessionUser', this.$store.getters['examinateur/examinateur_select_infos'].id).then(() => {
            // Mode lecture seule si l'user est un examinateur qui a soumis sa saisie
            this.readOnly = !!(this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN) && this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at);
            this.is_open = this.$store.getters['disponibilite/dispos_is_open'](this.$store.state.session.sessionSelect)
            // Si l'utilisateur a soumis et/ou validé on considère open pour afficher les données
            if (this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at || this.$store.state.sessionuser.sessionUser.disponibilites_validated_at) {
                this.is_open = true
            }
        })

        // Chargement des creneaux de l'examinateur
        await this.$store.dispatch('disponibilite/getCreneaux', { user_id: this.$store.getters['examinateur/examinateur_select_infos'].id })
        // Chargement des épreuves de corrections filtrées sur groupe_epreuve_id
        await this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.$store.state.session.sessionSelect.id, params: params })
        if (this.$store.getters['examinateur/examinateur_select_infos'].affectations.length !== 0 && this.$store.getters['examinateur/examinateur_select_infos'].ensembles) {
            for (let k = 0; k < this.$store.getters['examinateur/examinateur_select_infos'].ensembles.length; k++) {
                if (this.$store.getters['examinateur/examinateur_select_infos'].affectations.length !== 0 && this.$store.getters['examinateur/examinateur_select_infos'].ensembles[k] &&
                    this.$store.getters['examinateur/examinateur_select_infos'].ensembles[k].type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    let epreuve_correction_id_examinateur = this.$store.getters['examinateur/examinateur_select_infos'].affectations[0].epreuve_correction_id
                    for (let n = 0; n < this.$store.getters['examinateur/examinateur_select_infos'].affectations.length; n++) {
                        if (this.$store.getters['examinateur/examinateur_select_infos'].affectations[n].ensemble_id === parseInt(this.selected_serie_id.split('_')[0])) {
                            epreuve_correction_id_examinateur = this.$store.getters['examinateur/examinateur_select_infos'].affectations[n].epreuve_correction_id
                        }
                    }
                    const index = this.$store.state.epreuve.epreuvesCorrections.findIndex((ep: any) => ep.id === epreuve_correction_id_examinateur)
                    this.epreuve_correction = this.$store.state.epreuve.epreuvesCorrections[index]
                } else if (this.$store.getters['examinateur/examinateur_select_infos'].ensembles[k]) {
                    for (let i = 0; i < this.$store.getters['examinateur/examinateur_select_infos'].ensembles[k].groupeEpreuve.epreuves.length; i++) {
                        for (let j = 0; j < this.$store.getters['examinateur/examinateur_select_infos'].ensembles[k].groupeEpreuve.epreuves[i].epreuves.length; j++) {
                            const index = this.$store.state.epreuve.epreuvesCorrections.findIndex((ep: any) => ep.id === this.$store.getters['examinateur/examinateur_select_infos'].ensembles[k].groupeEpreuve.epreuves[i].epreuves[j].epreuve_correction_id)
                            this.epreuve_correction = this.$store.state.epreuve.epreuvesCorrections[index]
                        }
                    }
                }
            }            
        }

        // Affichage examinateur
        if (this.$props.context === 'examinateur') {
            // Check si l'examinateur est sur une épreuve de TP
            if (this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) {
                // Vérifie si l'examinateur est chef de saisie TP
                if (this.$store.getters['examinateur/examinateur_select_infos'].ensembles) {
                    for (let l = 0; l < this.$store.getters['examinateur/examinateur_select_infos'].ensembles.length; l++) {
                        if (this.$store.getters['examinateur/examinateur_select_infos'].ensembles[l].user_id === this.$store.getters['examinateur/examinateur_select_infos'].id) {
                        // Vérifie si le chef de TP a soumis ces dispos
                            this.readOnly = !!this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at;
                        } else {
                            this.readOnly = true
                        }
                    }
                } else {
                    this.readOnly = true
                }
            }
        }

        /**
         * READONLY SI:
         * * RESPONSABLE EPREUVE TP avec VALIDATION OK
         * * RESPONSABLE EPREUVE NO TP avec AJUSTEMENTS OK
         * * EXAMINATEUR TP ET NO TP avec SOUMISSION OK
         */

        if (this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW)) {
            if ((this.$store.state.sessionuser.sessionUser.ajustements_validated_at && this.epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP) ||
                (this.$store.state.sessionuser.sessionUser.disponibilites_validated_at && this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP)) {
                this.readOnly = true
            } else {this.readOnly = !this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE);}
        }

        // Chargement des séries
        if (!this.$store.getters['serie/series']?.length) {
            await this.$store.dispatch('serie/getSeries')
        }
        this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series,  this.selected_serie_id.split('_')[0])

        // Init du tableau des compteurs manquants
        for (const s in this.$store.state.serie.series) {
            this.compteurs_manques[this.$store.state.serie.series[s].id] = []
            this.compteurs_manques[this.$store.state.serie.series[s].id][EtatDispo.ETAT_PRIORITAIRE] = 0
            this.compteurs_manques[this.$store.state.serie.series[s].id][EtatDispo.ETAT_NON_PRIORITAIRE] = 0

            // Check si la planification est lancée sur au moins une série, on désactivera le bouton Recopier
            if (this.$store.state.serie.series[s].planifie !== 0) {
                this.planif_en_cours = true
            }
        }

        // Si l'épreuve est TP, on charge l'équipe de l'examinateur sélectionné
        if (this.epreuve_correction && this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) {
            const params: any = {}
            params.typePoste = PosteType.TYPE_PEDAGOGIQUE
            params.typePassation = TypePassation.TYPE_PASSATION_ORAL
            params.typeEntity = getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION)
            params.from = 'disponibilites'
            params['filter-ensemble_name'] = this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].name // TODO ensemble => ensembles
            await this.$store.dispatch('examinateur/getExaminateursEquipe', params)
        }

        // Chargement des compteurs de candidats réels
        this.getSeriesEnsemblesSelectContent()
    }

    /**
     * @description Retourne la couleur du compteur de série selon les minimums requis sur l'épreuve
     * @param {number} compteur - Compteur
     * @param {number} serie_id - Id de la série
     * @param {EtatDispo} prio - Priorité
     * @returns {string} - Couleur
     */
    getColorPrio(compteur: number, serie_id: number, prio: EtatDispo): string {
        let classe_css = 'fw-bold text-danger'
        switch (prio) {
            case EtatDispo.ETAT_PRIORITAIRE:
                if (this.epreuve_correction && compteur >= this.epreuve_correction.sceances_serie_min) {
                    classe_css = 'fw-bold text-success'
                    this.compteurs_manques[serie_id][prio] = 0
                } else {
                    this.compteurs_manques[serie_id][prio] = this.epreuve_correction.sceances_serie_min - compteur
                    classe_css = 'fw-bold text-danger'
                }
                break
            case EtatDispo.ETAT_NON_PRIORITAIRE:
                if (this.epreuve_correction && compteur >= this.$store.getters['disponibilite/get_priorite_next'](this.epreuve_correction.sceances_serie_min).value) {
                    classe_css = 'fw-bold text-success'
                    this.compteurs_manques[serie_id][prio] = 0
                } else {
                    classe_css = 'fw-bold text-danger'
                    this.compteurs_manques[serie_id][prio] = this.$store.getters['disponibilite/get_priorite_next'](this.epreuve_correction.sceances_serie_min).value - compteur
                }
                break
        }

        // Check les creneaux pour la duplication
        this.check_presence_creneaux_all_series()

        return classe_css
    }

    /**
     * @description Retourne le nombre de série de l'ensemble courant
     * @returns {number} - Nombre de série
     */
    getNbreSerieOfCurrentEnsemble(): number {
        for (let i = 0; i < this.seriesEnsemblesSelectContent.length; i++) {
            if (this.seriesEnsemblesSelectContent[i].ensemble_id === parseInt(this.selected_serie_id.split('_')[0])) {
                return this.seriesEnsemblesSelectContent[i].series.length
            }
        }
        return 0
    }

    /**
     * @description Retourne la couleur du total de série selon les minimums requis sur l'épreuve
     * @param {number} total - Total
     * @param {EtatDispo} prio - Priorité
     * @returns {string} - Couleur
     */
    getColorTotal(total: number, prio: EtatDispo): string {
        let classe_css = 'fw-bold text-danger'
        for (let i = 0; i < this.seriesEnsemblesSelectContent.length; i++) {
            if (this.seriesEnsemblesSelectContent[i].ensemble_id === parseInt(this.selected_serie_id.split('_')[0])) {
                switch (prio) {
                    case EtatDispo.ETAT_PRIORITAIRE:
                        if (this.epreuve_correction && total >= this.$store.getters['disponibilite/get_priorite_total'](this.epreuve_correction.sceances_serie_min, this.seriesEnsemblesSelectContent[i].series.length)) {
                            classe_css = 'fw-bold text-success'
                        } else {
                            classe_css = 'fw-bold text-danger'
                        }
                        break
                    case EtatDispo.ETAT_NON_PRIORITAIRE:
                        if (this.epreuve_correction && total >= this.$store.getters['disponibilite/get_priorite_next'](this.$store.getters['disponibilite/get_priorite_total'](this.epreuve_correction.sceances_serie_min, this.seriesEnsemblesSelectContent[i].series.length)).value) {
                            classe_css = 'fw-bold text-success'
                        } else {
                            classe_css = 'fw-bold text-danger'
                        }
                        break
                }
            }
        }

        return classe_css
    }

    /**
     * @description Vérifie si le semainier est disponible pour la soumission
     * @returns {void}
     */
    freshCompteursManques(): void {
        if (this.epreuve_correction && this.epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP) {
            let error_p = 0
            let error_np = 0
            for (const c in this.compteurs_manques) {
                if (this.compteurs_manques[c][EtatDispo.ETAT_PRIORITAIRE] !== 0) {
                    error_p++
                }
                if (this.compteurs_manques[c][EtatDispo.ETAT_NON_PRIORITAIRE] !== 0) {
                    error_np++
                }
            }

            this.compteur_prio_ok = error_p === 0
            this.compteur_no_prio_ok = error_np === 0
            this.getEtatGlobalCreneaux()
        } else if (this.epreuve_correction && this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) {
            this.compteur_no_prio_ok = true
            this.compteur_prio_ok = true
            this.getEtatGlobalCreneaux()
        }
    }

    /**
     * @description Récupération de l'état global des créneaux
     * @returns {void}
     */
    getEtatGlobalCreneaux(): void {
        const examinateur_select_infos = this.$store.getters['examinateur/examinateur_select_infos']
        this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)

        const compteursByEnsemble = []
        for (let i = 0; i < examinateur_select_infos.ensembles.length; i++) {
            let ep = null
            for (let j = 0; j < this.$store.state.serie.series.length; j++) {
                ep = this.selectedSerieIdChangeHandler(examinateur_select_infos.ensembles[i].id, this.$store.state.serie.series[j].id)
                break
            }
            // const ep = this.$store.state.epreuve.epreuvesCorrections.find((ec: any) => ec.groupe_epreuve_id === examinateur_select_infos.ensembles[i].groupe_epreuve_id)
            compteursByEnsemble.push({
                epreuve: ep,
                series: this.getSeriesOfEpreuves(examinateur_select_infos.ensembles[i].groupeEpreuve.epreuves, examinateur_select_infos.ensembles[i].id),
                ensemble_name: examinateur_select_infos.ensembles[i].name,
                ensemble_id: examinateur_select_infos.ensembles[i].id,
                compteurs : this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.seriesEnsemblesSelectContent[i].ensemble_id),
                prio_min: ep.sceances_serie_min,
                non_prio_min: this.$store.getters['disponibilite/get_priorite_next'](ep.sceances_serie_min).value
            })
        }
        this.compteursAnomalie = []

        for (let k = 0; k < compteursByEnsemble.length; k++) {
            let found = false
            const serie_prio = []
            const serie_non_prio = []
            for (let l = 0; l < compteursByEnsemble[k].series.length; l++) {
                if (compteursByEnsemble[k].compteurs[compteursByEnsemble[k].series[l].id].prio < compteursByEnsemble[k].prio_min) {
                    serie_prio.push({ serie: compteursByEnsemble[k].series[l].name, prio:compteursByEnsemble[k].prio_min - compteursByEnsemble[k].compteurs[compteursByEnsemble[k].series[l].id].prio })
                    found = true
                }
                if (compteursByEnsemble[k].compteurs[compteursByEnsemble[k].series[l].id].non_prio < compteursByEnsemble[k].non_prio_min) {
                    serie_non_prio.push({ serie: compteursByEnsemble[k].series[l].name, prio:compteursByEnsemble[k].non_prio_min - compteursByEnsemble[k].compteurs[compteursByEnsemble[k].series[l].id].non_prio })
                    found = true
                }
            }
            if (found) {
                this.compteursAnomalie.push({
                    ensemble_name: compteursByEnsemble[k].ensemble_name,
                    serie_prio:serie_prio,
                    serie_non_prio: serie_non_prio
                })
            }
        }
        this.selectedSerieIdChangeHandler()
        this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series, this.selected_serie_id.split('_')[0])
    }

    /**
     * @description Ouvre la modale précisée
     * @param {string} modale - Modale
     * @returns {void}
     */
    showModales(modale: string): void {
        this.$store.state.disponibilite.error_dispos = null
        this.$store.state.sessionuser.error_session_user = null
        switch (modale) {
            case 'soumission':
                this.freshCompteursManques()
                this.showModaleSoumission = true
                break
            case 'validation':
                this.showModaleValidation = true
                break
            case 'invalidation':
                this.showModaleInvalidation = true
                break
            case 'annulation_soumission':
                this.showModaleAnnuleSoumission = true
                break
            case 'ajustements':
                this.showModaleAjustements = true
                break
            case 'invalidationAjustements':
                this.showModaleInvalidationAjustements = true
                break
            case 'duplication':
                this.showModaleDuplicateSerie = true
                break
            case 'raz_serie':
                this.showModaleDeleteCreneauxSerie = true
                break
            case 'personaliser':
                this.showModalePersonaliser = true
                break
        }
    }

    /**
     * @description Ferme toutes les modales de confirmation
     * @returns {void}
     */
    closeModales(): void {
        this.$store.state.disponibilite.error_dispos_dispos = null
        this.$store.state.sessionuser.error_session_user = null
        this.showModaleSoumission = false
        this.showModaleValidation = false
        this.showModaleInvalidation = false
        this.showModaleAnnuleSoumission = false
        this.showModaleAjustements = false
        this.showModaleInvalidationAjustements = false
        this.showModaleDuplicateSerie = false
        this.showModaleDeleteCreneauxSerie = false
        this.showModaleModifJourneeType = false
    }

    /**
     * @description Confirmation de la modale
     * @param {string} mode - Mode
     * @returns {void}
     */
    confirmModale(mode: string): void {
        this.refresh_creneaux = false
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        let message_toast_enCours = ''
        let message_toast_success = ''
        let params = {}
        switch (mode) {
            case 'soumettre':
                message_toast_enCours = 'Soumission en cours ...'
                message_toast_success = 'Les disponibilités ont été soumises !'
                params = {
                    disponibilites_submit : 1
                }
                break
            case 'annuler':
                message_toast_enCours = 'Annulation de la soumission en cours ...'
                message_toast_success = 'La soumission des disponibilités a été annulée !'
                params = {
                    disponibilites_submit : 0,
                    commentaire_rejet: this.commentaire
                }
                break
            case 'valider':
                message_toast_enCours = 'Validation en cours ...'
                message_toast_success = 'Les disponibilités ont été validées !'
                params = {
                    disponibilites_validate : 1
                }
                break
            case 'invalider':
                message_toast_enCours = 'Invalidation en cours ...'
                message_toast_success = 'Les disponibilités ont été invalidées !'
                params = {
                    disponibilites_validate : 0
                }
                break
            case 'validerAjustements':
                message_toast_enCours = 'Validation en cours ...'
                message_toast_success = 'Les ajustements des disponibilités ont été validés !'
                params = {
                    ajustements_validate : 1
                }
                break
            case 'invaliderAjustements':
                message_toast_enCours = 'Invalidation en cours ...'
                message_toast_success = 'Les ajustements des disponibilités ont été invalidés !'
                params = {
                    ajustements_validate : 0
                }
                break
        }

        this.$bvToast.toast(message_toast_enCours, infosToaster)
        this.$store.dispatch('sessionuser/updateSessionUser', { user_id: this.$store.getters['examinateur/examinateur_select'].id, payload: params })
            .then((response) => {
                // MAJ de l'examinateur dans la collection
                const params = {
                    user_id: this.$store.getters['examinateur/examinateur_select'].id,
                    info_users: response.data.data
                }
                this.$store.commit('examinateur/MAJ_INFO_SESSION_USER', params)

                // Seulement dans le cas du responsable, on rappele les stats, l'intervenant étant interdit sur ce script
                if (this.$props.context !== 'examinateur') {
                    // Récupération des nouvelles données stats
                    this.$store.dispatch('sessionuser/getSessionUserStatsvalidation')
                        .then(() => {
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast(message_toast_success, succesToaster)

                            // Envoie l'ordre de refresh les creneaux pour afficher la ligne horaire supplémentaire
                            this.refresh_creneaux = true
                            this.readOnly = !!(this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN) && this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at);
                            this.closeModales()
                        })
                } else {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(message_toast_success, succesToaster)
                    this.closeModales()
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Retourne le message en fonction des dates sur la publication des disponibilités
     * @returns {any} - Message avec classe CSS
     */
    getInfoPublicationDispos(): any {
        const session_active = this.$store.getters['session/sessionSelect']
        this.is_open = this.$store.getters['disponibilite/dispos_is_open'](session_active)
        let message = ''
        let class_text = ''

        if (this.is_open) {
            // Ouverte & programmée
            if (this.todayDate &&  session_active &&  session_active.disponibilites_start_at) {
                message = 'Disponibilités :  la saisie est ouverte du ' + formatDateSinTime(session_active.disponibilites_start_at) + ' au ' + formatDateSinTime(session_active.disponibilites_end_at) + ' inclus'
                class_text = 'barre_text_valide'
            }

            // Ouverte
            if (session_active &&  session_active.disponibilites_start_at === null && session_active.disponibilites_end_at) {
                message = 'Disponibilités :  la saisie est ouverte jusqu\'au ' + formatDateSinTime(session_active.disponibilites_end_at) + ' inclus'
                class_text = 'barre_text_valide'
            }
        } else {
            // Fermée ou non publiée
            if (session_active && session_active.disponibilites_start_at === null && session_active.disponibilites_end_at === null) {
                message = 'Disponibilités : Saisie fermée'
                class_text = 'barre_text_defaut'
            }
            if (session_active && session_active.disponibilites_start_at === null && session_active.disponibilites_end_at) {
                message = 'La saisie des disponibilités est fermée depuis le ' + formatDateSinTime(session_active.disponibilites_end_at) + ' au soir'
                class_text = 'barre_text_defaut'
            }

            // Fermée & Programmée
            if (this.todayDate &&  session_active &&  session_active.disponibilites_start_at) {
                message = 'Disponibilités : la saisie est programmée du ' + formatDateSinTime(session_active.disponibilites_start_at) + ' au ' + formatDateSinTime(session_active.disponibilites_end_at) + ' inclus'
                class_text = 'barre_text_soumis'
            }
        }

        return { message: message, class_css: class_text }
    }

    /**
     * @description Retourne une classe CSS selon l'état de la validation des infos
     * @returns {string} - Classe CSS
     */
    getClassBarreBoutons(): string {
        let class_css = 'barre_default'
        if (this.$store.state.sessionuser.sessionUser) {
            if (this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at && !this.$store.state.sessionuser.sessionUser.disponibilites_validated_at) {
                class_css = 'barre_soumis'
            } else if (this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at && this.$store.state.sessionuser.sessionUser.disponibilites_validated_at) {
                class_css = 'barre_valide'
            } else if (this.$store.state.sessionuser.sessionUser.commentaire_rejet && this.$store.state.sessionuser.sessionUser.commentaire_rejet.disponibilites) {
                class_css = 'barre_rejet'
            }
        }

        return class_css
    }

    /**
     * @description Duplication des créneaux de la série sélectionnée
     * @returns {void}
     */
    duplicate_serie(): void {
        this.$store.dispatch('disponibilite/cloneSerie', { user_id: this.$store.getters['examinateur/examinateur_select'].id, serie_id: this.selected_serie_id.split('_')[1], ensemble_id: this.selected_serie_id.split('_')[0] })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 500
                }
                this.$bvToast.toast('Duplication terminée.', succesToaster)

                // MAJ des compteurs selon contexte
                if (this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) {
                    this.$store.dispatch('disponibilite/getCreneauxEnsemble', { ensemble_id: this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].id }).then(() => { // TODO ensemble => ensembles
                        this.refreshCompteurs(this.$store.getters['disponibilite/get_compteurs_serie_journee_TP'](this.$store.state.serie.series))
                    })
                } else {
                    this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series,  this.selected_serie_id.split('_')[0])
                }

                this.closeModales()
            }).catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * @description Supprime les crénaux de la série sélectionnée
     * @returns {void}
     */
    delete_creneaux(): void {
        this.refresh_creneaux = false
        this.$store.dispatch('disponibilite/clearSerie', { user_id: this.$store.getters['examinateur/examinateur_select'].id, serie_id: this.selected_serie_id.split('_')[1], ensemble_id: this.selected_serie_id.split('_')[0]  }).then(() => {
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 500
            }
            this.$bvToast.toast('Effacement terminé.', succesToaster)
            this.$store.dispatch('disponibilite/getCreneaux', { user_id: this.$store.getters['examinateur/examinateur_select_infos'].id }).then(() => {
            // MAJ des compteurs selon contexte
                if (this.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP) {
                    this.$store.dispatch('disponibilite/getCreneauxEnsemble', { ensemble_id: this.$store.getters['examinateur/examinateur_select_infos'].ensembles[0].id }).then(() => { // TODO ensemble => ensembles
                        this.refreshCompteurs(this.$store.getters['disponibilite/get_compteurs_serie_journee_TP'](this.$store.state.serie.series))
                        this.refresh_creneaux = true
                        this.closeModales()
                    })
                } else {
                    this.$store.getters['disponibilite/get_compteurs_serie_journee'](this.$store.state.serie.series)
                    this.$store.getters['disponibilite/get_compteurs_serie_journee_by_ensemble'](this.$store.state.serie.series,  this.selected_serie_id.split('_')[0])

                    this.refresh_creneaux = true
                    this.closeModales()
                }
            })
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * @description Vérifie la présence de crénaux sur les séries
     * @returns {void}
     */
    check_presence_creneaux_all_series(): void {
        for (const s in this.$store.state.disponibilite.compteurs_series_journees) {
            const serie =  this.$store.state.disponibilite.compteurs_series_journees[s]
            if (serie.prio === 0 || serie.non_prio === 0) {
                this.has_creneaux_all_series = false
            }
        }
    }

    /**
     * @description Récupère les compteurs quand on est en ensemble type TP
     * @param {any} compteurs - Compteurs
     * @returns {void}
     */
    refreshCompteurs(compteurs: any): void {
        this.compteurs = compteurs
    }

    /**
     * @description Téléchargement du PDF des disponibilités d'un examinateur
     * @returns {void}
     */
    dl_pdf(): void {
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('examinateur/getPDFDisposExaminateur', { user_id: this.$store.getters['examinateur/examinateur_select_infos'].id })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || 'Disponibilites_' + this.$store.getters['examinateur/examinateur_select_infos'].name + '.pdf'
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * @description Ferme la consultation du PDF
     * @returns {void}
     */
    closeShowPdf(): void {
        this.show_pdf = false
        this.source_doc = null
        this.file_name = null
    }

    /**
     * @description Affiche ou non l'indicateur des crénaux sélectionnés par le responsable
     * @param {boolean} state - Etat
     * @returns {void}
     */
    update_create_by_other(state: boolean): void {
        this.create_by_other = state
    }

    /**
     * @description Avant montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        if (!this.$store.getters['session/sessionSelect']) {
            await this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
        }
        await this.initDatas()
    }
}
