





























































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { RequestSearchByCandidat } from '@/types/CandidatIncompatible'

@Component({
    computed: {
        ...mapGetters('candidatIncompatible', ['listeCandidatsIncompatibles', 'loading', 'totalRows', 'lastPage', 'totalPage', 'listeCandidatsIncompatiblesGroupee', 'listIsLoading', 'selectedExaminateur', 'resultatRechercheParCandidat', 'allEtablissements', 'get_meta_recherche_Candidat'])
    }
})

export default class RechercheParCandidat extends Vue {


    /* Utiliser pour vérifier si une recherche a deja eu lieux et afficher le bon message si le resultat ne comporte pas de resultat */
    firstSearch                        = false
    resultat: any                      = []
    meta: any                          = []
    loadSearch                         = false
    listeEtablissements                = []
    candidatSelectionneParUser         = 0
    recherche: RequestSearchByCandidat = {
        user_id: this.$store.getters['candidatIncompatible/selectedExaminateur'],
        name: '',
        first_name: '',
        etablissement_id: '*',
        classe: '*',
        code: ''
    }

    @Watch('resultatRechercheParCandidat')
    watchResultatRechercheParCandidat(): void {
        this.resultat = this.$store.getters['candidatIncompatible/resultatRechercheParCandidat']
        this.meta     = this.$store.getters['candidatIncompatible/get_meta_recherche_Candidat']
    }

    @Watch('selectedExaminateur')
    getExaminateur(): void {
        this.recherche.user_id = this.$store.getters['candidatIncompatible/selectedExaminateur']
    }

    @Watch('allEtablissements')
    watchGetEtablissementById(): void {
        this.listeEtablissements = this.$store.getters['candidatIncompatible/allEtablissements']
    }

    beforeMount(): void {
        this.$store.dispatch('candidatIncompatible/GET_ALL_ETABLISSEMENTS')
    }

    /**
     * @description Fonction de declenchement de la recherche. reinit resultat, prend l'user_id de l'examinateur et fait un dispatch
     * @returns {void}
     */
    rechercher(): void {
        this.candidatSelectionneParUser = 0
        this.loadSearch                 = true
        this.resultat                   = []
        this.recherche.user_id          = this.$store.getters['candidatIncompatible/selectedExaminateur']
        this.$store.dispatch('candidatIncompatible/RECHERCHE_PAR_CANDIDAT', this.recherche).then(() => {
            this.loadSearch = false
        })
        this.firstSearch = true
    }

    /**
     * @description Sauvegarde de la selection de l'utilisateur
     * @param {number} candidatId - id du candidat selectionné
     * @returns {void}
     */
    setSelectedCandidat(candidatId: number): void {
        this.candidatSelectionneParUser = candidatId
    }

    /**
     * @description Dispatch des candidats incompatibles / emit le nom de l'onglet à afficher
     * @returns {void}
     */
    addIncompatibles(): void {
        const params = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            candidats : [
                { candidat_id : this.candidatSelectionneParUser }
            ]
        }
        this.$store.dispatch('candidatIncompatible/addCandidats', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
        this.$emit('afficher', 'affichage_candidats_incompatibles')
        this.cleanSearchArea()
    }

    /**
     * @description Fonction app lors du clic sur annuler, RAS dans le store de resultatRechercheCandidat/ résultat de recherche et envois le nom de l'onglet à afficher
     * @returns {void}
     */
    annulerRecherche(): void {
        this.$emit('afficher', 'affichage_candidats_incompatibles')
        this.cleanSearchArea()
    }

    /**
     * @description Suppression des champs de recherche
     * @returns {void}
     */
    cleanSearchArea(): void {
        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.candidatSelectionneParUser = 0
        this.firstSearch = false
        this.recherche = {
            user_id: this.$store.getters['candidatIncompatible/selectedExaminateur'],
            name: '',
            first_name: '',
            etablissement_id: '*',
            classe: '*',
            code: ''
        }
    }

    /**
     * @description Recuperation des classes d'un établissement pour l'afficher dans le champ recherche
     * @returns {string}
     */
    classes(): string {
        return (this.listeEtablissements
            ?.find((element: { id: any }) => element.id === this.recherche.etablissement_id) as any)
            ?.classes || ''
    }
}
