export enum MessageIndicationType {
    PRESENCE = "Un examinateur sera considéré comme présent l'après-midi s'il a sur son planning au moins une séance planifiée qui commence après 13h00 ce jour.",
    ADRESSES = "Par défaut, l’adresse principale est utilisée pour tous les types d’adresses. Si l’adresse de livraison des sujets est par exemple différente de l'adresse principale, vous devez cocher la case adéquate et d’indiquer l’adresse concernée, qui s’ajoutera à l’adresse principale.",
    MESSAGE_CANDIDAT = 'Cette partie est réservée aux informations importantes que vous souhaitez communiquer aux candidats. Ces informations seront inscrites sur les convocations. Exemple : « Restauration possible au sein de l’établissement ».',
    SAISIE_SALLES = '* Merci de renseigner obligatoirement au moins une salle pour les candidats ayant besoin d’aménagements et une salle pour candidat(s) isolé(s). <br/><br/>* Pour les salles de type aménagements, vous ne devez indiquer que la capacité maximale de la salle. <br/><br/>* Merci de répartir l’ensemble des candidats, y compris les candidats handicapés, dans les salles classiques. Ces candidats seront ensuite répartis par le concours dans les salles aménagées ou isolées selon les besoins. L’estimation candidats correspond aux effectifs de l’année précédente. Ils seront mis à jour avec les données réelles, quand celles-ci seront connues.',
    SAISIE_NB_INTERVENANTS = '"Secrétaire candidat handicapé" : Cette information est à titre indicatif. Elle sera remplie par le Concours en cas de besoin. <br/><br/>Pour rappel : Minimum deux surveillants par salle, dont le chef de salle.',
    GENERAL = "Attention : Si vous souhaitez enregistrer les informations sans les valider, cliquez sur le bouton « Enregistrer ». <br/>Le bouton « Soumettre » permet de verrouiller l'enregistrement et d'indiquer au concours que toutes les informations sont correctes. <br/>Si vous avez cliqué sur le bouton « Soumettre » et que vous souhaitez modifier une information, merci de nous contacter.",
    MINIMA_TP = "Le nombre défini pour une épreuve de TP correspond à l'ensemble des candidats de l'équipe d'examinateur.",
    DISPOS = "Attention : Les informations de disponibilité sont enregistrées automatiquement au fur et à mesure de vos saisies.<br/>Le bouton « Soumettre » permet de verrouiller l'enregistrement et d'indiquer au concours que toutes les informations sont correctes.<br/>Si vous avez cliqué sur le bouton « Soumettre » et que vous souhaitez modifier une information, merci de nous contacter.",
    GENERAL_NO_SAVE = "Attention : Le bouton « Soumettre » permet de verrouiller l'enregistrement et d'indiquer au concours que toutes les informations sont correctes. <br/>Si vous avez cliqué sur le bouton « Soumettre » et que vous souhaitez modifier une information, merci de nous contacter.",
    RECEPTION_SUJETS = "Pour chaque sujet, merci de renseigner si vous avez réceptionné la quantité attendue dans un état conforme.<br/>Si ce n'est pas le cas, merci de l'indiquer en précisant la quantité reçue et les anomalies relatives.",
    RECEPTION_PAP = "Pour chaque fourniture, merci de renseigner si vous avez réceptionné la quantité attendue dans un état conforme.<br/>Si ce n'est pas le cas, merci de l'indiquer en précisant la quantité reçue et les anomalies relatives.",
    RECEPTION_ADM = "Pour chaque fourniture, merci de renseigner si vous l'avez réceptionné dans un état conforme.<br/>Si ce n'est pas le cas, merci de l'indiquer en précisant les anomalies relatives.",
    INDICATION_APPLICATION_MARGE = "Si la quantité commandée saisie est inférieure au nombre de candidats passant l'épreuve, le nombre de sujets commandés sera égal au nombre de candidats passant l'épreuve.",
}
