













































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal, BButton } from 'bootstrap-vue'
import { TypeIncompatibilite } from '@/types/Candidat'
import { Ability } from '@/types/Ability'
import { mapGetters } from 'vuex'


@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'b-modal': BModal,
        'b-button': BButton
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class AffichageCandidatsIncompatibles extends Vue {
    @Prop() candidatsIncompatibles: any
    @Prop() viewOnly?: boolean
    @Prop() afficherButtonsRechercher?: boolean
    @Prop() isLoading?: boolean | false

    TypeIncompatibilite = TypeIncompatibilite
    Ability = Ability
    /* Boolean de popup de validation de la suppression */
    modalSuppression                = false
    candidatsSelectedByUser: any    = []
    message_retrait = ''

    /**
     * @description Ajout incompatibilité par centre
     * @param {any} centre
     * @returns {void}
     */
    deleteCentre(centre: any): void {
        const candidatsIds: any[] = []
        for (const i in centre) {
            this.message_retrait = 'Vous allez retirer l\'établissement <strong>' + (centre[i][0].etablissement ? (centre[i][0].etablissement.ville ? centre[i][0].etablissement.ville.toUpperCase() : 'Ville non définie') + ' - ' + centre[i][0].etablissement.name : 'Établissement non défini') + '</strong> de la liste des incompatibles.<br/>Confirmez-vous cette action ?'
            centre[i].forEach((element: any) => {
                const candidatId = { candidat_id: element.id }
                candidatsIds.push(candidatId)
            })
        }
        this.candidatsSelectedByUser = candidatsIds
        this.modalSuppression = true
    }

    /**
     * @descirption Ajout incompatibilité par filiere
     * @param {any} filiere
     * @returns {void}
     */
    deleteFiliere(filiere: any): void {
        this.message_retrait = 'Vous allez retirer la classe <strong>' + filiere[0].classe + '</strong> de l\'établissement <strong>' +  (filiere[0].etablissement ? (filiere[0].etablissement.ville ? filiere[0].etablissement.ville.toUpperCase() : 'Ville non définie') + ' - ' + filiere[0].etablissement.name : 'Établissement non défini') + '</strong> de la liste des incompatibles.<br/>Confirmez-vous cette action ?'
        this.modalSuppression = true
        const candidatsIds: any[] = []
        filiere.forEach((element: any) => {
            const candidatId = { candidat_id: element.id }
            candidatsIds.push(candidatId)
        })
        this.candidatsSelectedByUser = candidatsIds
    }

    /**
     * @description Ajout incompatibilité par candidat
     * @param {any} candidat
     * @returns {void}
     */
    deleteCandidat(candidat: any): void {
        this.message_retrait = 'Vous allez retirer le candidat <strong>' + candidat.code + ' ' + candidat.name + ' ' + candidat.first_name + '</strong> de la liste des incompatibles.<br/>Confirmez-vous cette action ?'
        this.modalSuppression = true
        this.candidatsSelectedByUser = [{ candidat_id: candidat.id }]
    }

    /**
     * @description Fonction de la modale de suppression
     * @returns {void}
     */
    cancelSuppression(): void {
        this.candidatsSelectedByUser = []
        this.modalSuppression = false
    }

    canDeleteCentre(centre: any): boolean {
        if (centre) {
            for (const filiere in centre) {
                if (!this.canDeleteFiliere(centre[filiere])) {
                    return false
                }
            }
        }
        return true
    }

    canDeleteFiliere(filiere: any): boolean {
        if (filiere) {
            for (let i = 0; i < filiere.length; i++) {
                if (filiere[i].incompatibilites[0].type === TypeIncompatibilite.INCOMPAT_TYPE_DOUBLEMENT_AUTO && !this.$store.getters['auth/can'](Ability.ORAL_PREPA_MANAGE)) {
                    return false
                }
            }
        }
        return true
    }

    validerSuppression(): void {
        const params = {
            user_id: this.$store.state.candidatIncompatible.examinateurSelectionne.id,
            candidats: this.candidatsSelectedByUser
        }

        this.modalSuppression = false
        this.$store.dispatch('candidatIncompatible/deleteCandidatsIncompatibles', params)
    }

    /**
     * @description Retourne le nombre de candidats du centre
     * @param {any} centre
     * @returns {number}
     */
    getNbCandidats(centre: any): number {
        return Object.values(centre)
            .reduce((acc: number, filiere: any) => acc + filiere.length, 0) || 0
    }
}

